/**
 * Fetch the REx API
 * @param url
 * @param data
 */
import {StrapiObject} from "./models/StrapiData";


export async function collectionFetcher<TR>(url: string) : Promise<StrapiObject<TR>[]> {
    const baseUrl =  process.env.REACT_APP_STRAPI_URL +  '/api';
    const response = await fetch(baseUrl + url );
    // TODO: Process error
     const data: StrapiObject<TR>[] = (await response.json()).data;
     console.log('fetched datad', data);
     return data;

}