import {FC, useEffect, useState} from "react";
import {objectFetcher} from "../objectFetcher";
import {MediaData, Medium} from "../models/StrapiData";
import {Spinner, Image} from "react-bootstrap";

interface MediaProps {
    id: number;
    size: 'thumbnail' | 'small' | 'medium' | 'large';
    alt: string | null;
    className?: string;
}


const Media: FC<MediaProps> = ({id, size, alt, className}) => {

    const [imgData, setImgData] = useState<Medium | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string | null>(null);
    const [imgAlt, setImgAlt] = useState<string>('');

    async function getImgData(): Promise<Medium> {
        const urlApi = '/media/' + id + '?populate=*'
        const data = await objectFetcher<MediaData>(urlApi);
        setImgData(data.file.data.attributes);
        setImgAlt(alt !== null ? alt : data.file.data.attributes.alternativeText);
        return data.file.data.attributes;
    }

    async function getImgUrl() {
        const data = await getImgData();
        const url = data.formats[size].url;
        setImgUrl(url);
    }

    useEffect(() => {
        setLoading(true);
        getImgUrl();
        setLoading(false);
    }, [id]);

    if (loading || !imgUrl) {
        return <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    }
    return <Image  className={className} fluid={true} alt={imgAlt} src={process.env.REACT_APP_STRAPI_URL +  imgUrl}/>

};

export default Media;