import {FC} from "react";
import { Medium } from "../models/StrapiData";
import { Image } from "react-bootstrap";

interface ContentImageProps {
    id: number;
    size: 'thumbnail' | 'small' | 'medium' | 'large';
    medium: Medium;
}


const ContentImage: FC<ContentImageProps> = ({id, size, medium}) => {

    const imgUrl = medium.formats[size].url;
    return <div className='content-image'>
        <Image  fluid={true} rounded={true} key={'content-image-' + id} alt={medium.alternativeText} src={process.env.REACT_APP_STRAPI_URL +  imgUrl}/>
    </div>;
};

export default ContentImage;