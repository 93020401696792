/**
 * Fetch the REx API
 * @param url
 * @param data
 */
import {StrapiData} from "./models/StrapiData";


export async function objectFetcher<TR>(url: string) : Promise<TR> {
    const baseUrl =  process.env.REACT_APP_STRAPI_URL +  '/api';
    const response = await fetch(baseUrl + url );
    // TODO: Process error
     const data: StrapiData<TR> = await response.json();
     console.log(data);
     return data.data.attributes;

}