import React, {useEffect, useState} from 'react';
import './App.scss';
import {Container, Placeholder} from "react-bootstrap";
import {Row, Col} from 'react-bootstrap';
import Header from './components/Header';
import Footer from "./components/Footer";
import {collectionFetcher} from "./collectionFetcher";
import {Page, StrapiObject} from "./models/StrapiData";
import ContentDisplay from "./components/ContentDisplay";
import {Route, Routes} from "react-router";
import Home from "./components/Home";

type ContentPages = StrapiObject<Page>[];

function App() {
    const [loading, setLoading] = useState<boolean>(true);
    const [pageList, setPageList] = useState<ContentPages | null>(null);
    const [homePageId, setHomePageId] = useState<number | null>(null);

    useEffect(() => {
        const fetchPages = async () => {
            setLoading(true);
            const route = '/pages'
            const data = await collectionFetcher<Page>(route);

            setPageList(data.sort((p1, p2) => p1.attributes.position - p2.attributes.position));
            setLoading(false);
        }
        fetchPages();
    }, []);

    useEffect(() => {
        if (pageList) {
            const homePages = pageList.filter(p => p.attributes.is_home_page);
            if (homePages.length > 0) {
                setHomePageId(homePages[0].id);
            }
        }
    }, [pageList]);

    if (loading) {
        return <Placeholder animation={'glow'}></Placeholder>
    }
    if (!pageList) {
        // todo display error
    }


    // useEffect(() => {
    //     document.title = 'La fanfare de Nanterre - ' + title;
    // }, [title])
    return (

        <Container fluid={'xxl'}>
            <header role='banner'>
                 <Container fluid={'xxl'}>
                <Row>
                    <Col>
                        <Header pages={pageList!.filter(p => p.attributes.menu === 'header')}></Header>
                    </Col>
                </Row>
                 </Container>
            </header>
            <main role='main'>
                 <Container fluid={'xxl'}>
                <Row>
                    <Col>
                        <Routes>
                            {pageList!.map(page => <Route
                                key={'route-' + page.attributes.label}
                                path={'/' + page.attributes.label}
                                element={<ContentDisplay id={page.id}/>}
                            />)
                            }
                             <Route path={'/'} element={<Home/>}/>
                        </Routes>
                    </Col>
                </Row>
                 </Container>
            </main>
            <footer role='contentinfo'>
                <Row>
                    <Col>
                        <Footer pages={pageList!.filter(p => p.attributes.menu === 'footer')}/>
                    </Col>
                </Row>
            </footer>
        </Container>
    );
}

export default App;
