import React, {FC, useEffect, useRef, useState} from "react";
import {objectFetcher} from "../objectFetcher";
import {Col, Container, Image, Placeholder, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import {Page} from "../models/StrapiData";
import ContentImage from "./ContentImage";

type ContentDisplayProps = {
    id: number;
}

const ContentDisplay: FC<ContentDisplayProps> = ({id}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Page | null>(null);

    const h1RefElement = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const fetchContent = async () => {
            setLoading(true);
            const route = '/pages/' + id +  '?populate=*';
            const data = await objectFetcher<Page>(route);
            setData(data);
            setLoading(false);
            h1RefElement.current?.focus();
        }
        fetchContent();

    }, [id])

    useEffect(() => {
        if (data) {
            document.title = data.titre;
            const metaDescription = document.head.querySelector<Element>('head > meta[name="description"]');
            console.log(metaDescription);
            if (metaDescription) {
                metaDescription.setAttribute('content', data.description);
            }
        }
        h1RefElement.current?.focus();
    }, [data])


    if (loading) {
        return <Placeholder animation={'glow'}></Placeholder>
    }
    if (!data) {
        return null;
    }

    return <div className={'content-' + data.label}>
        <h1 ref={h1RefElement} tabIndex={-1}><ReactMarkdown
            components={{ // remove 'p' inside h1 tag
                p: React.Fragment,
            }}
            children={data.titre}/></h1>
        <Container fluid={'md'}>
            <Row className={'gap-0'}>
                <Col md={8} className={'me-0 ms-0 pe-0 ps-0'}>
                    <ReactMarkdown className={'article-content'}
                                   components={{'img': ({node,  ...props}) => <Image
                                           fluid={true}
                                           src={process.env.REACT_APP_STRAPI_URL! + props.src}
                                           alt={props.alt}
                                           style={props.style}/>}}
                                   children={data.contenu}/>
                </Col>
                {data.images?.data && <Col className={'content-images me-0 ms-0 pe-0 ps-0'}>
                    {data.images.data.map( img => <ContentImage key={ 'img-' + img.id} id={img.id} size={'small'} medium={img.attributes}/>)}
                </Col> }
            </Row>
        </Container>
    </div>
}

export default ContentDisplay;
