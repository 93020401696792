import {Navbar, Nav, NavLink} from "react-bootstrap";
import {FC} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {Page, StrapiObject} from "../models/StrapiData";

type FooterProps = { pages: StrapiObject<Page>[] };


const Footer: FC<FooterProps> = (pages) => {
    return <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed={"bottom"}>
            <Nav className="me-auto">
                {pages.pages.map(p => <LinkContainer key={'footer-link-' + p.id} to={'/' + p.attributes.page_id}>
                    <NavLink>{p.attributes.titre_menu}</NavLink>
                </LinkContainer>)}
            </Nav>
        </Navbar>
    </>
}

export default Footer;