import React, {FC, useEffect, useRef, useState} from "react";

import {Card, Col, Placeholder, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import {Medium, Nouvelle, StrapiObject} from "../models/StrapiData";
import {collectionFetcher} from "../collectionFetcher";

type ContentNouvelle = StrapiObject<Nouvelle>[];

const News: FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ContentNouvelle>([]);
    const h1RefElement = useRef<HTMLHeadingElement>(null);
    useEffect(() => {
        const fetchContent = async () => {
            setLoading(true);
            const route = '/nouvelles?populate=*';
            const data = await collectionFetcher<Nouvelle>(route);
            setData(data);
            setLoading(false);
            h1RefElement.current?.focus();
        }
        fetchContent();

    }, [])

    if (loading) {
        return <Placeholder animation={'glow'}></Placeholder>
    }
    if (!data) {
        return null;
    }

    const urlImg = (img: Medium) => {
        return process.env.REACT_APP_STRAPI_URL + img.formats['small'].url;
    }

    return <>
        <Row>
            <Col><h1 ref={h1RefElement} tabIndex={-1}>Les p'tites nouvelles</h1></Col>
        </Row>
        <Row xs={1} md={1} className="g-4" role='list'>
            {data.sort((d1, d2) => d2.id - d1.id).map((item, idx) => <Col key={item.attributes.label + idx} role='listitem'>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <ReactMarkdown
                                components={{ // remove 'p' inside h1 tag
                                    p: React.Fragment,
                                }}
                                children={item.attributes.titre}/>
                        </Card.Title>
                        <Card.Text as={'div'}>
                            <ReactMarkdown className={'news-content'}
                                           components={{
                                               'img': ({node, ...props}) => <img
                                                   src={process.env.REACT_APP_STRAPI_URL! + props.src}
                                                   alt={props.alt}
                                                   style={props.style}/>
                                           }}
                                           children={item.attributes.contenu}/>
                        </Card.Text>
                        {item.attributes.images?.data
                            && item.attributes.images.data.map(img => <Card.Img
                                key={'news-img-' + img.id}
                                src={urlImg(img.attributes)}
                                alt={img.attributes.alternativeText}/>)}
                    </Card.Body>
                </Card>
            </Col>)
            }
        </Row>
    </>
}

export default News;