import {Container, Navbar, Nav, NavLink} from "react-bootstrap";
import {FC} from "react";
import {Link} from 'react-router-dom'
import Media from "./Media";
import {LinkContainer} from "react-router-bootstrap";
import {Page, StrapiObject} from "../models/StrapiData";

type HeaderProps = {pages: StrapiObject<Page>[]};


const Header: FC<HeaderProps> = (pages) => {
    return <>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
            <Container>
                <Navbar.Brand>
                    <Link to="/" >
                        <Media className={'logo-header'} key={'logo'} id={1} size={'thumbnail'} alt="La fanfare de Nanterre"/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {pages.pages.map( p => <LinkContainer  key={'header-link-' + p.attributes.page_id} to={'/' + p.attributes.page_id}>
                            <NavLink>{p.attributes.titre_menu}</NavLink>
                            </LinkContainer>)}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
}

export default Header;
