import {FC, useEffect} from "react";
import News from "./News";
import {Col, Row} from "react-bootstrap";
import Media from "./Media";

const Home: FC = () => {

    useEffect(() => {
        document.title = "Page d'accueil - Fanfare de Nanterre"
    }, [])
    return <div className='home-page'>
        <Row>
            <Col md={6}>
                <Media className={'logo-homepage'} id={3} size={'medium'} alt={'La fanfare de Nanterre'}/>
            </Col>
            <Col>
                <News/>
            </Col>
        </Row>
    </div>
}

export default Home;